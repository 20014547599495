<script setup>
    import { ref, inject, computed, defineProps, onMounted, onBeforeUnmount } from "vue";
    import { app } from "o365-modules";
    import { InjectionKeys } from 'o365-utils';
    import AppButton from "o365.pwa.vue.components.layout.AppButton.vue";
    import IndexedDBHandler from 'o365.pwa.modules.client.IndexedDBHandler.ts';
    import { confirm } from 'o365-vue-services';
    import { $t } from "o365-utils";

    const { pwaStoreKey } = InjectionKeys;
    
    const { state: offlineState, uninstallApp, uninstallAllApps, checkForAppUpdate, installApp, updateApp, eventEmitter } = inject(pwaStoreKey);

    const props = defineProps({
        dataObject: {
            type: Object,
            required: false,
            default: null,
        }
    });

    const appState = ref(null);
    const pwaState = ref(null);

    const isAppButtonDisabled = ref(false);
    const isActionButtonLoading = ref(false);
    const isUninstallButtonLoading = ref(false);

    const isHome = computed(() => {
        return app.id === 'pwa-home';
    });

    const appInstalled = computed(() => {
        return pwaState.value?.isAppInstalled ?? null;
    });

    const appHasUpdate = computed(() => {
        return pwaState.value?.hasAppUpdateAvailable ?? null;
    });

    const appActionClass = computed(() => {
        if (!appInstalled.value) {
            return 'bi-download text-primary';
        }

        if (appHasUpdate.value) {
            return 'bi-arrow-repeat text-primary';
        }

        return '';
    });

    const appActionText = computed(() => {
        if (!appInstalled.value && appInstalled.value !== null) {
            return $t('Install app');
        }
        
        if (appHasUpdate.value && appHasUpdate.value !== null) {
            return $t('Update app');
        }

        return '';
    });

    const buttonClass = computed(() => {
        const classList = [
            'gap-2',
            'd-flex',
            'fw-semibold',
            'no-decoration',
            'align-items-center',
            'justify-content-center'
        ];
        
        return classList.join(' ');
    });

    const handleUninstalled = () => {
        window.location.reload();
    };

    const handleAppActionClicked = async () => {
        if (isAppButtonDisabled.value) {
            return;
        }

        isAppButtonDisabled.value = true;
        isActionButtonLoading.value = true;

        if (!appInstalled.value) {
            await installApp(app.id, undefined, false);
        }

        if (appHasUpdate.value) {
            await updateApp(app.id, undefined, false);
        }

        isAppButtonDisabled.value = false;
        isActionButtonLoading.value = false;
    };
    
    const handleAppUninstall = async (id) => {
        if(!appInstalled.value) {
            return;
        }

        isUninstallButtonLoading.value = true;

        confirm({
            title: $t('Uninstall ' + (isHome.value ? 'all apps' : 'app')),
            message: $t('Are you sure you want to uninstall ' + (isHome.value ? 'all apps' : 'the app') + ' and remove any unsaved data? This action cannot be undone.')
        })
        .then(() => {
            if(isHome.value){
                uninstallAllApps();
            } else {
                uninstallApp(id);
            }
        })
        .catch(() => {
            isUninstallButtonLoading.value = false;
            return;
        });

        isUninstallButtonLoading.value = false;
        return;
    };

    const getAppFromIndexedDB = async (appId) => {
        appState.value = await IndexedDBHandler.getApp(appId);
        pwaState.value = await appState.value?.pwaState ?? null;
    }

    const checkForAppUpdates = async (appId) => {
        await checkForAppUpdate(appId);
        await getAppFromIndexedDB(appId);
    };

    const initializeAsync = async () => {
        await getAppFromIndexedDB(app.id);
    };

    initializeAsync();

    onMounted(() => {
        eventEmitter.on('allAppsUninstalled', handleUninstalled);
        eventEmitter.on('appInstalled', checkForAppUpdates);
        eventEmitter.on('appUpdated', checkForAppUpdates);
    });
    
    onBeforeUnmount(() => {
        eventEmitter.off('allAppsUninstalled', handleUninstalled);
        eventEmitter.off('appInstalled', checkForAppUpdates);
        eventEmitter.off('appUpdated', checkForAppUpdates);
    });
</script>

<template>
    <div class="d-flex flex-column bg-light rounded gap-2 p-2">
        <h4 class="m-0">{{ $t('Actions') }}</h4>
        
        <hr class="m-0">

        <AppButton v-if="appActionText !== ''" @click="() => handleAppActionClicked()">
            <template #buttonContent>
                <component is="a" :class="buttonClass" :style="`pointer-events:${isAppButtonDisabled ? 'none' : 'all'}`">
                    <i :class="appActionClass"></i>

                    {{ appActionText }}

                    <div v-if="isActionButtonLoading" class="spinner-border spinner-border-sm text-primary"></div>
                </component>
            </template>
        </AppButton>

        <AppButton v-if="appInstalled" @click="() => handleAppUninstall(app.id)">
            <template #buttonContent>
                <component :is="isAppButtonDisabled ? 'span' : 'a'" :class="buttonClass, { 'text-black': isAppButtonDisabled }">
                    <i class="bi bi-x-circle text-danger"></i>

                    {{ isHome ? $t('Uninstall all apps') : $t('Uninstall app') }}

                    <div v-if="isUninstallButtonLoading" class="spinner-border spinner-border-sm text-primary"></div>
                </component>
            </template>
        </AppButton>
        
        <slot name="syncActions"></slot>
    </div>
</template>
